<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddMentorsModel from '@/modules/mentors/add-mentors-model';
import BaseButton from '@/shared/components/BaseButton.vue';

const { fields } = AddMentorsModel;

export default {
  name: 'AddUser',
  components: {
    BaseButton,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: 0,
    },
    groupId: {
      type: Number,
      required: false,
      default: 0,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      files: [],
      error: '',
      Img: [],
      model: {},
      update: 0,
    };
  },
  methods: {
    openAddUser() {
      const addUserWindow = document.querySelector('.add-item');
      addUserWindow.classList.toggle('show');
      if (addUserWindow.classList.value.includes('hide')) {
        addUserWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addUserWindow.classList.toggle('hide');
        }, 300);
      }
    },
    getUser(idUser) {
      const getUserPath = '/WebGetUser.php';

      const data = new FormData();
      data.append('id', idUser);
      fetch(server + getUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok' && json.name) {
            [this.model.first_name, this.model.last_name] = json.name.split(' ');
            this.model.email = json.username;
            this.update += 1;
          }
        });
    },
    addUsers() {
      this.openAddUser();
      const data = new FormData();
      let addUserPath = '';
      if (parseInt(this.userId, 10) !== 0) {
        addUserPath = '/WebEditUser.php';
        data.append('id', this.userId);
      } else {
        addUserPath = '/WebAddUser.php';
      }

      data.append('name', `${this.model.first_name} ${this.model.last_name}`);
      data.append('username', this.model.email);
      data.append('role', 'manager');
      data.append('active', true);

      data.append('image', this.Img[0] ? this.Img[0] : '');
      fetch(server + addUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok') {
            this.callback();
          }
        });
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    previewImgs(event) {
      this.files = event.currentTarget.files;
      this.error = '';
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        this.Img = values;
      });
    },
    removeFromGroup(id) {
      const deleteUserPath = '/WebRemoveUserFromGroup.php';
      const data = new FormData();
      data.append('id', id);
      data.append('group_id', this.groupId);

      fetch(server + deleteUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            this.openAddUser();
            this.callback();
          }
        });
    },
  },
  beforeMount() {
    if (parseInt(this.userId, 10) !== 0) {
      this.getUser(this.userId);
    } else {
      this.model.first_name = '';
      this.model.last_name = '';
      this.model.email = '';
    }
  },
};
</script>
<template>
  <div class="add-item" :key="this.update">
    <div class="add-item-left" v-on:click="openAddUser">
    </div>
    <div class="add-item-window">
      <form class="form" v-on:submit.prevent="addUsers()" id="add_dep_form">

        <div style="height: 100vh" :key="this.model.name">
          <div class="menu">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddUser"/>
            <div class="title">
              {{$t('EMPLOYEE.ADD_EMPLOYEE') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit">
              <unicon fill="white" height="16" width="16" name="save"/>
              {{ $t('GENERAL.SAVE') }}
            </BaseButton>
          </div>
          <div class="content">
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.BASIC_INFO') }}
                </div>
              </b-col>
            </b-row>
            <div style="display: inline-flex;width: 100%;padding: 0 24px">
              <div style="margin-right: 24px">
                <div class="upload-photo">
                  <img :src="this.Img" v-if="this.Img.length !== 0" alt=""/>
                  <unicon class="unicon-image" name="image" v-if="this.Img.length === 0"
                          width="80" height="80" fill="white"/>
                  <div class="upload-photo-over">
                    <input
                      type="file"
                      style="z-index: 1;opacity: 0;position: absolute;bottom: 0;
                      height: 190px;width: 190px;"
                      accept="image/*"
                      ref="uploadInput"
                      @change="previewImgs"
                      multiple
                    />
                    <unicon name="camera" width="40" height="40" fill="white"/>
                  </div>
                </div>

              </div>
              <div style="width: -webkit-fill-available;">
                <div style="width: -webkit-fill-available;">
                  <div class="form-floating-2">
                    <label for="floatingInput">
                      {{ fields.first_name.label }}</label>
                    <input type="text"
                           class="form-control-2"
                           :id="fields.first_name.name"
                           v-model="model[fields.first_name.name]"
                           :placeholder="fields.first_name.label"
                           required
                           maxlength="200"
                    >
                  </div>
                </div>
                <div style="width:-webkit-fill-available;">
                  <div class="form-floating-2">
                    <label for="floatingInput">
                      {{ fields.last_name.label }}</label>
                    <input type="text"
                           class="form-control-2"
                           :id="fields.last_name.name"
                           v-model="model[fields.last_name.name]"
                           :placeholder="fields.last_name.label"
                           required
                           maxlength="200"
                    >
                  </div>
                </div>
              </div>

            </div>
            <b-row>
              <div class="form-separator">
              </div>
            </b-row>
            <b-row>
              <b-col class="col">
                <div class="form-floating-2 custom-input" style="margin-bottom: 24px">
                  <label for="floatingInput">
                    {{ fields.email.label }}</label>
                  <unicon fill="#828282" height="16" width="16" name="envelope"
                          style="cursor: pointer;top:36px"/>
                  <input type="text"
                         class="form-control-2"
                         :id="fields.email.name"
                         v-model="model[fields.email.name]"
                         :placeholder="fields.email.label"
                         required
                         maxlength="200"
                         disabled
                         style="padding-left: 40px!important;"
                  >
                </div>
              </b-col>
            </b-row>
            <b-row>
              <div class="form-separator" v-if="userId !== 0">
              </div>
            </b-row>
            <b-row>
              <b-col class="col" md="12" v-if="userId !== 0">
                <BaseButton
                  :variant="'danger'"
                  :callback="removeFromGroup"
                  :callback-params="userId"
                  style="width: 100%"
                >
                  <unicon name="trash" fill="#FFFFFF" height="16" width="16"/>
                  {{ $t('USER.REMOVE_FROM_GROUP') }}
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>
