<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import { VueEllipseProgress } from 'vue-ellipse-progress';
import AddUser from '../../../../shared/components/AddUser.vue';

const store = {
  state: {
    secondSelection: 'progress',
    students: [],
    depInfo: [],
    gradient1: {
      radial: false,
      colors: [
        {
          color: '#03D1AC',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#14D355',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient2: {
      radial: false,
      colors: [
        {
          color: '#1694D0',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#3687FF',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient3: {
      radial: false,
      colors: [
        {
          color: '#CE56EC',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#7B22ED',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient4: {
      radial: false,
      colors: [
        {
          color: '#F03B7C',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#FF3767',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    gradient5: {
      radial: false,
      colors: [
        {
          color: '#FF8743',
          offset: '0',
          opacity: '1',
        },
        {
          color: '#F39C19',
          offset: '100',
          opacity: '1',
        },
      ],
    },
    userId: 0,
  },
};

export default {
  name: 'ManagementStudentsPage',
  components: {
    VueEllipseProgress,
    AddUser,
  },
  data() {
    return {
      store,
      model: {},
    };
  },
  methods: {
    ListStudents() {
      const listStudPath = '/WebListStudentsForBranch.php';
      const data = new FormData();
      data.append('branch_id', this.$route.params.parent_id);
      data.append('branch_type', 'group');
      if (this.model.search !== undefined && this.model.search !== '') {
        data.append('filter', this.model.search);
      }
      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.users) {
            store.state.students = json.users;
          }
        });
    },
    getDepartament() {
      const getDepPath = '/WebGetBranchDetails.php';

      const data = new FormData();
      data.append('id', parseInt(this.$route.params.parent_id, 10));
      data.append('type', 'group');
      fetch(server + getDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else {
            store.state.depInfo = json;
          }
        });
    },
    openAddUser() {
      const addUserWindow = document.querySelector('.add-item');
      addUserWindow.classList.toggle('show');
      if (addUserWindow.classList.value.includes('hide')) {
        addUserWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addUserWindow.classList.toggle('hide');
        }, 300);
      }
    },
    editUser(id) {
      setTimeout(() => {
        store.state.userId = id;
      }, 300);
      this.openAddUser();
    },

  },
  beforeMount() {
    this.ListStudents();
    this.getDepartament();
  },
};
</script>
<template>
<div>
  <div class="main-select-students" v-if="store.state.depInfo.length !== 0">
    <div class="main-select-students-1"
         v-if="store.state.secondSelection === 'progress'">
      <b-table-simple class="custom-table">
        <b-thead class="first-thead">
          <b-tr>
            <td style="width: 6%;">
              <div class="back" v-on:click="$router.go(-1)">
                <unicon name="angle-left-b" y="-6"
                        width="18" height="18" fill="#828282"/>
              </div>
            </td>
            <td style="width: 28%;">
              <div class="item-details">
                <div class="d-inline-flex">
                  <div class="item-image">
                    <img :src="store.state.depInfo.logo ? store.state.depInfo.logo
                    : '/media/group.jpg'" alt=""/>
                  </div>
                  <div class="item-info">
                    <div style="width: 100%">
                      <div class="headline-bold-16"  style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis;">
                        <unicon height="20" width="20" name="users-alt"
                                fill="#00BA69"></unicon>
                        {{ store.state.depInfo.name }}
                      </div>
                      <div class="contains">
                        <div class="ellipse green"></div>
                        <div class="caption-2-reg-12 basic-2">
                          {{ store.state.depInfo.students_list.length }}
                          {{ $t('DEPARTAMENTS.STUDENTS') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td  style="width: 60%;" colspan="4">
              <div style="height: 50px;padding: 5px 0;">
                <div class="second-select">
                  <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'progress' ? 'active' : '')"
                       v-on:click="store.state.secondSelection = 'progress'">

                    <unicon height="20" width="20" name="chart-line" fill="#1694D0"></unicon>
                    {{ $t("DEPARTAMENTS.PROGRESS") }}
                  </div>
                  <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'knowledge' ? 'active' : '')"
                       v-on:click="store.state.secondSelection = 'knowledge'"
                       style="margin-right: 0!important;">

                    <unicon height="20" width="20" name="chart" fill="#1694D0"></unicon>
                    {{ $t("DEPARTAMENTS.LEARNING_STAGES") }}

                  </div>
                </div>
              </div>
            </td>
            <td style="width: 6%;">
            </td>
          </b-tr>
        </b-thead>
        <b-thead>
          <b-tr>
            <b-th style="width: 6%;">
              {{ $t("DEPARTAMENTS.NO") }}
            </b-th>
            <b-th style="width: 28%;">
              {{ $t("DEPARTAMENTS.STUDENT") }}
            </b-th>
            <b-th style="width: 14%;">
              {{ $t("GENERAL.TAGS") }}
            </b-th>
            <b-th style="width: 10%;">
              {{ $t("DEPARTAMENTS.RAPIDITY") }}
            </b-th>
            <b-th style="width: 18%;">
              {{ $t("DEPARTAMENTS.ACCURACY") }}
            </b-th>
            <b-th style="width: 18%;">
              {{ $t("DEPARTAMENTS.MEMORIZATION") }}
            </b-th>
            <b-th style="width: 6%;">
              <unicon name="setting" fill="#5E5873"/>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
<!--        v-on:click="$router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/progress`);"    -->
          <b-tr v-for="(item,i) in store.state.students" :key="i"  v-on:click="editUser(item.id);">
            <b-td

                class="item-no" style="width: 6%;">
              {{ i }}
            </b-td>
            <b-td
             style="width: 28%;">
              <div class="user-details">
                <div class="d-inline-flex">
                  <div class="user-image">
                    <img :src="item.image ? item.image:
                       '/media/user.jpg'" alt="" />
                  </div>
                  <div class="user-info">
                    <div style="width: 100%">
                      <div class="headline-medium-16"  style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis;">
                        <unicon height="24" width="24" name="user-circle" fill="#FDAB3D"
                                style="margin-right: 6px"></unicon>
                        {{ item.name }}
                      </div>
                    </div>
                    <div class="caption-2-reg-12 basic-2"></div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td style="width: 14%;">
              <div style="display: flex;height: 50px;justify-content: center;align-items: center;
                 width: -webkit-fill-available;">
                <div style="height: fit-content">
                  <div style="font-weight: 700;font-size: 14px;
                    line-height: 17px;" class="basic-1">
                    {{ parseInt(0)}}%
                  </div>
                  <!--                <b-progress :max="100" style="min-width: 70px">
                                    <b-progress-bar variant="primary"
                                      :value="parseInt0">
                                    </b-progress-bar>
                                    <b-progress-bar variant="secondary"
                                    :value="parseInt(100)">
                                    </b-progress-bar>
                                  </b-progress>-->
                </div>
              </div>
            </b-td>
            <b-td
               style="width: 10%;">
              <div style="display: flex;height: 50px;justify-content: center">
                <unicon name="stopwatch" fill="#E2445C"
                        height="24" width="24" style="margin-right: 8px; margin-top: 13px"/>
                <div style="margin-top: 13px">
                  soon
                </div>
              </div>
            </b-td>
            <b-td
                style="width: 18%;">
              <div style="display: flex;height: 50px;justify-content: center;align-items: center;
                 width: -webkit-fill-available;">
                <div style="height: fit-content;width: -webkit-fill-available;">
                  <div style="font-weight: 700;font-size: 14px;
                    line-height: 17px;" class="basic-1">
                    <div style="display: inline-flex;width: 100%">
                      <div :class="'w-'+(parseInt(item.accuracy/25)*25 > 0 ?
                    parseInt(item.accuracy/25)*25 : '25')" style="min-width: 29px;
                        color:#00CA72">
                        {{ item.accuracy }}%
                      </div>
                      <div :class="'w-'+(parseInt((100-item.accuracy)/25)*25 > 0 ?
                    parseInt((100-item.accuracy)/25)*25 : '25')" style="min-width: 29px;
                        color:#E2445C">
                        {{ 100 - item.accuracy }}%
                      </div>
                    </div>
                  </div>
                  <b-progress :max="100" >
                    <b-progress-bar :value="item.accuracy" variant="success">
                    </b-progress-bar>
                    <b-progress-bar :value="100 - item.accuracy"
                                    variant="danger">
                    </b-progress-bar>
                  </b-progress>
                </div>
              </div>
            </b-td>
            <b-td
              style="width: 18%;">
              <div style="display: flex;height: 50px;justify-content: center;align-items: center;
                 width: -webkit-fill-available;">
                <div style="height: fit-content;width: -webkit-fill-available;">
                  <div style="font-weight: 700;font-size: 14px;
                    line-height: 17px;" class="basic-1">
                    <div style="display: inline-flex;width: 100%">
                      <div :class="'w-' + parseInt(item.memorization.green_percent /25)*25"
                           style="min-width: 29px;color:#00CA72">
                        {{ item.memorization.green_percent }}%
                      </div>
                      <div :class="'w-' +parseInt(item.memorization.yellow_percent /25)*25"
                           style="min-width: 29px;color:#FDAB3D">
                        {{ item.memorization.yellow_percent }}%
                      </div>
                      <div :class="'w-' + parseInt(item.memorization.red_percent /25)*25"
                           style="min-width: 29px;color:#E2445C">
                        {{ item.memorization.red_percent }}%
                      </div>
                    </div>
                  </div>
                  <b-progress :max="100">
                    <b-progress-bar :value="item.memorization.green_percent" variant="success">
                    </b-progress-bar>
                    <b-progress-bar :value="item.memorization.yellow_percent"
                                    variant="warning">
                    </b-progress-bar>
                    <b-progress-bar :value="item.memorization.red_percent"
                                    variant="danger">
                    </b-progress-bar>
                  </b-progress>
                </div>
              </div>
            </b-td>
            <b-td style="width: 6%;">
              <div class="action mt-3" style="cursor: pointer;"
                   v-on:click="editUser(item.id);">
                <unicon name="ellipsis-v" fill="#333333"
                        height="16" width="16"/>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="main-select-students-2"
         v-if="store.state.secondSelection === 'knowledge'">
      <b-table-simple class="custom-table">
        <b-thead class="first-thead">
          <b-tr>
            <td style="width: 6%;">
              <div class="back" v-on:click="$router.go(-1)">
                <unicon name="angle-left-b" y="-6"
                        width="18" height="18" fill="#828282"/>
              </div>
            </td>
            <td style="width: 28%;">

              <div class="item-details">
                <div class="d-inline-flex">
                  <div class="item-image">
                    <img :src="store.state.depInfo.logo ? store.state.depInfo.logo
                    : '/media/group.jpg'" alt=""/>
                  </div>
                  <div class="item-info">
                    <div style="width: 100%">
                      <div class="headline-bold-16"  style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis;">
                        <unicon height="20" width="20" name="users-alt"
                                fill="#00BA69"></unicon>
                        {{ store.state.depInfo.name }}
                      </div>
                      <div class="contains">
                        <div class="ellipse green"></div>
                        <div class="caption-2-reg-12 basic-2">
                          {{ store.state.depInfo.students_list.length }}
                          {{ $t('DEPARTAMENTS.STUDENTS') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td  style="width: 60%;" colspan="5">
              <div style="height: 50px;padding: 5px 0;">
                <div class="second-select">
                  <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'progress' ? 'active' : '')"
                       v-on:click="store.state.secondSelection = 'progress'">

                    <unicon height="20" width="20" name="chart-line" fill="#1694D0"></unicon>
                    {{ $t("DEPARTAMENTS.PROGRESS") }}
                  </div>
                  <div :class="'subhead-reg-14 basic-1 selector '
            + (store.state.secondSelection === 'knowledge' ? 'active' : '')"
                       v-on:click="store.state.secondSelection = 'knowledge'"
                       style="margin-right: 0!important;">

                    <unicon height="20" width="20" name="chart" fill="#1694D0"></unicon>
                    {{ $t("DEPARTAMENTS.LEARNING_STAGES") }}

                  </div>
                </div>
              </div>
            </td>
            <td style="width: 6%;">
            </td>
          </b-tr>
        </b-thead>
        <b-thead>
          <b-tr>
            <b-th style="width: 6%;">
              {{ $t("DEPARTAMENTS.NO") }}
            </b-th>
            <b-th style="width: 28%;">
              {{ $t("DEPARTAMENTS.STUDENT") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.ELEMENTARY") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.MEDIUM") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.EXPERT") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.APPLICATION") }}
            </b-th>
            <b-th style="width: 12%;">
              {{ $t("DEPARTAMENTS.LIFEHACK") }}
            </b-th>
            <b-th style="width: 6%;">
              <unicon name="setting" fill="#5E5873"/>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item,i) in store.state.students" :key="i">
            <b-td  class="item-no" style="width: 6%;" v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);">
              {{ i }}
            </b-td>
            <b-td  style="width: 28%;">
              <div class="user-details"   v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);">
                <div class="d-inline-flex">
                  <div class="user-image">
                    <img :src="item.image ? item.image:
                       '/media/user.jpg'" alt="" />
                  </div>
                  <div class="user-info">
                    <div style="width: 100%">
                      <div class="headline-medium-16"  style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis;">
                        <unicon height="24" width="24" name="user-circle" fill="#FDAB3D"
                                style="margin-right: 6px"></unicon>
                        {{ item.name }}
                      </div>
                    </div>
                    <div class="caption-2-reg-12 basic-2"></div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);" style="width: 12%;">
              <div class="d-inline-flex">
                <div class="d-inline-flex">
                  <vue-ellipse-progress
                      class="ellipse-progress-green"
                      :size="48"
                      :color="store.state.gradient1"
                      :progress="item.statistics.knowledge.level1.percent"
                      :legend="true"
                      :legendValue="item.statistics.knowledge.level1.percent"
                      :legendFormatter="({currentValue}) => currentValue + ' %'"
                      legendClass="legend-custom-style"
                      :thickness="5"
                      emptyThickness="10%"
                  />
                </div>
                <div class="d-inline-flex" style="margin-left: 4px;align-items: center;">
                  <div>
                    <div class="basic-2 caption-caps-med-12">
                      {{item.statistics.knowledge.level1.percent -
                    item.statistics.knowledge.level1.previous_percent}}%
                      <unicon :name="item.statistics.knowledge.level1.percent -
                    item.statistics.knowledge.level1.previous_percent < 0 ? 'arrow-down':'arrow-up'"
                              :fill="item.statistics.knowledge.level1.percent -
                    item.statistics.knowledge.level1.previous_percent < 0 ? '#E2445C':'#00CA72'"
                              height="16" width="16"/>
                    </div>
                    <div class="basic-3" style="font-weight: 600;font-size: 10px;
                    line-height: 14px;">
                      {{ $t("DEPARTAMENTS.PER_MONTH") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td  v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);" style="width: 12%;">
              <div class="d-inline-flex">
                <div class="d-inline-flex">
                  <vue-ellipse-progress
                      class="ellipse-progress-blue"
                      :size="48"
                      :color="store.state.gradient2"
                      :progress="item.statistics.knowledge.level2.percent"
                      :legend="true"
                      :legendValue="item.statistics.knowledge.level2.percent"
                      :legendFormatter="({currentValue}) => currentValue + ' %'"
                      legendClass="legend-custom-style"
                      :thickness="5"
                      emptyThickness="10%"
                  />
                </div>
                <div class="d-inline-flex" style="margin-left: 4px;align-items: center;">
                  <div>
                    <div class="basic-2 caption-caps-med-12">
                      {{item.statistics.knowledge.level2.percent -
                    item.statistics.knowledge.level2.previous_percent}}%
                      <unicon :name="item.statistics.knowledge.level2.percent -
                    item.statistics.knowledge.level2.previous_percent < 0 ? 'arrow-down':'arrow-up'"
                              :fill="item.statistics.knowledge.level2.percent -
                    item.statistics.knowledge.level2.previous_percent < 0 ? '#E2445C':'#00CA72'"
                              height="16" width="16"/>
                    </div>
                    <div class="basic-3" style="font-weight: 600;font-size: 10px;
                    line-height: 14px;">
                      {{ $t("DEPARTAMENTS.PER_MONTH") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td  v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);" style="width: 12%;">
              <div class="d-inline-flex">
                <div class="d-inline-flex">
                  <vue-ellipse-progress
                      class="ellipse-progress-purple"
                      :size="48"
                      :color="store.state.gradient3"
                      :progress="item.statistics.knowledge.level3.percent"
                      :legend="true"
                      :legendValue="item.statistics.knowledge.level3.percent"
                      :legendFormatter="({currentValue}) => currentValue + ' %'"
                      legendClass="legend-custom-style"
                      :thickness="5"
                      emptyThickness="10%"
                  />
                </div>
                <div class="d-inline-flex" style="margin-left: 4px;align-items: center;">
                  <div>
                    <div class="basic-2 caption-caps-med-12">
                      {{item.statistics.knowledge.level3.percent -
                    item.statistics.knowledge.level3.previous_percent}}%
                      <unicon :name="item.statistics.knowledge.level3.percent -
                    item.statistics.knowledge.level3.previous_percent < 0 ? 'arrow-down':'arrow-up'"
                              :fill="item.statistics.knowledge.level3.percent -
                    item.statistics.knowledge.level3.previous_percent < 0 ? '#E2445C':'#00CA72'"
                              height="16" width="16"/>
                    </div>
                    <div class="basic-3" style="font-weight: 600;font-size: 10px;
                    line-height: 14px;">
                      {{ $t("DEPARTAMENTS.PER_MONTH") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);" style="width: 12%;">
              <div class="d-inline-flex">
                <div class="d-inline-flex">
                  <vue-ellipse-progress
                      class="ellipse-progress-red"
                      :size="48"
                      :color="store.state.gradient4"
                      :progress="item.statistics.knowledge.level4.percent"
                      :legend="true"
                      :legendValue="item.statistics.knowledge.level4.percent"
                      :legendFormatter="({currentValue}) => currentValue + ' %'"
                      legendClass="legend-custom-style"
                      :thickness="5"
                      emptyThickness="10%"
                  />
                </div>
                <div class="d-inline-flex" style="margin-left: 4px;align-items: center;">
                  <div>
                    <div class="basic-2 caption-caps-med-12">
                      {{item.statistics.knowledge.level4.percent -
                    item.statistics.knowledge.level4.previous_percent}}%
                      <unicon :name="item.statistics.knowledge.level4.percent -
                    item.statistics.knowledge.level4.previous_percent < 0 ? 'arrow-down':'arrow-up'"
                              :fill="item.statistics.knowledge.level4.percent -
                    item.statistics.knowledge.level4.previous_percent < 0 ? '#E2445C':'#00CA72'"
                              height="16" width="16"/>
                    </div>
                    <div class="basic-3" style="font-weight: 600;font-size: 10px;
                    line-height: 14px;">
                      {{ $t("DEPARTAMENTS.PER_MONTH") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td v-on:click="
            $router.push(`/management/student/student-results/`
                  + `${$route.params.parent_id}/${item.id}/knowledge`);" style="width: 12%;">
              <div class="d-inline-flex">
                <div class="d-inline-flex">
                  <vue-ellipse-progress
                      class="ellipse-progress-orange"
                      :size="48"
                      :color="store.state.gradient5"
                      :progress="item.statistics.knowledge.level5.percent"
                      :legend="true"
                      :legendValue="item.statistics.knowledge.level5.percent"
                      :legendFormatter="({currentValue}) => currentValue + ' %'"
                      legendClass="legend-custom-style"
                      :thickness="5"
                      emptyThickness="10%"
                  />
                </div>
                <div class="d-inline-flex" style="margin-left: 4px;align-items: center;">
                  <div>
                    <div class="basic-2 caption-caps-med-12">
                      {{item.statistics.knowledge.level5.percent -
                    item.statistics.knowledge.level5.previous_percent}}%
                      <unicon :name="item.statistics.knowledge.level5.percent -
                    item.statistics.knowledge.level5.previous_percent < 0 ? 'arrow-down':'arrow-up'"
                              :fill="item.statistics.knowledge.level5.percent -
                    item.statistics.knowledge.level5.previous_percent < 0 ? '#E2445C':'#00CA72'"
                              height="16" width="16"/>
                    </div>
                    <div class="basic-3" style="font-weight: 600;font-size: 10px;
                    line-height: 14px;">
                      {{ $t("DEPARTAMENTS.PER_MONTH") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td style="width: 6%;">
              <div class="action mt-3" style="cursor: pointer;"
                   v-on:click="editUser(item.id);">
                <unicon name="ellipsis-v" fill="#333333"
                        height="16" width="16"/>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
  <div class="add-item hide">
    <AddUser :key="store.state.userId" :user-id="store.state.userId"
             :group-id="this.$route.params.parent_id"
             :callback="ListStudents"
    >
    </AddUser>
  </div>
</div>
</template>
<style>
.main-select-students .progress{
height: 9px;
}

.main-select-students .progress.progress-height-32{
  height: 32px;
  border-radius: 8px;
  margin-top: 10px;
}

.main-select-students .progress .bg-primary{
background-color: #1694D0!important;
}
.main-select-students .progress .bg-secondary{
background-color: #CDD1FF!important;
}
.main-select-students .progress .bg-success{
background-color: #00CA72!important;
}
.main-select-students .progress .bg-danger{
background-color: #E2445C!important;
}
.main-select-students .progress .bg-warning{
background-color: #FDAB3D!important;
}

.main-select-students .progress-relative{
  position: relative;
}

.main-select-students .progress-absolute{
  position: absolute;
  top: 8px;
  left: 8px;
  color: #00854B;
  font-weight: 600;
  font-size: 14px;
  height: 16px;
}
.main-select-students .progress-absolute svg {
  margin-top: -4px;
}
.legend-custom-style{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.ellipse-progress-green{
  background-color: #f0fffa;
  border-radius: 50%;
}
.ellipse-progress-blue{
  background-color: #f3f6ff;
  border-radius: 50%;
}
.ellipse-progress-purple{
  background-color: #faf4fe;
  border-radius: 50%;
}
.ellipse-progress-red{
  background-color: #fff3f6;
  border-radius: 50%;
}
.ellipse-progress-orange{
  background-color: #fff9f2;
  border-radius: 50%;
}
.main-select-students-1 tbody tr:hover,.main-select-students-1 tbody tr:hover,
.main-select-students-2 tbody tr:hover,.main-select-students-2 tbody tr:hover{
  background-color: #E8F4FA;
  cursor: pointer;
}
.main-select-students-1 tbody tr:hover td,.main-select-students-1 tbody tr:hover td,
.main-select-students-2 tbody tr:hover td,.main-select-students-2 tbody tr:hover td{
  background-color: transparent;
}
</style>
