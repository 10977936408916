import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`EMPLOYEE.FIELDS.${name.toUpperCase()}`);

const fields = {
  first_name: new StringField('first_name', label('first_name'), {
    required: true,
  }),
  last_name: new StringField('last_name', label('last_name'), {
    required: true,
  }),
  email: new StringField('email', label('email'), {
    required: true,
  }),
  type: new StringField('type', label('type'), {
    required: true,
  }),
};

export default class AddMentorsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
